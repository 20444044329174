import React, { useState } from "react";
import { Card, CardImg, CardBody, Button, Form, FormGroup, Input, Label, Alert } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { webRegister } from "../services/auth.service";

import logo from "../images/JUAN-STREAM-FULL-LOGO.png";

const SignUp = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [reEnterPwd, setReEnterPwd] = useState("");
  const [message, setMessage] = useState("");
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const onChangeRePwd = (e) => {
    const password = e.target.value;
    setReEnterPwd(password);
  };
  const handleWebRegister = (e) => {
    e.preventDefault();
    const formData = { email: email, username: username, password: password, reEnterPwd: reEnterPwd };
    webRegister(formData).then((data) => {
      if (data.token) {
        navigate("/addvideo");
      } else {
        setMessage(data.msg);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      }
    });
  };
  return (
    <div>
      <Card
        style={{
          width: "20rem",
          margin: "2rem auto",
        }}
      >
        <CardImg alt="image" src={logo} top width="100%" />
        <CardBody>
          {message && <Alert color="danger" fade={false}>{message}</Alert>}
          <Form inline onSubmit={handleWebRegister}>
            <FormGroup floating>
              <Input
                id="email"
                name="email"
                placeholder="Email"
                type="email"
                onChange={onChangeEmail}
                required
              />
              <Label for="email">Email</Label>
            </FormGroup>{" "}
            <FormGroup floating>
              <Input
                id="username"
                name="username"
                placeholder="Username"
                type="text"
                onChange={onChangeUsername}
                required
              />
              <Label for="username">Username</Label>
            </FormGroup>{" "}
            <FormGroup floating>
              <Input
                id="password"
                name="password"
                placeholder="Password"
                type="password"
                onChange={onChangePassword}
                required
              />
              <Label for="password">Password</Label>
            </FormGroup>{" "}
            <FormGroup floating>
              <Input
                id="repassword"
                name="repassword"
                placeholder="Re-enter Password"
                type="password"
                onChange={onChangeRePwd}
                required
              />
              <Label for="repassword">Re-enter Password</Label>
              </FormGroup>{" "}
            <Button
              style={{
                width: "10rem",
                background: "#000",
                color: "white",
                border: "none",
                fontWeight: "700",
                fontSize: "20px",
                transform: "translate(40%,30%)",
                marginBottom: "2rem",
              }}
            >
              Sign Up
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
    
  );
};

export default SignUp;